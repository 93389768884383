export const languageAvailabe = ['en', 'hi'];

export const mutliLng = {
    "en": {

        // #############Home

        "select_language": "Select Language",

        "chirayu_haryana_pmjay": "CHIRAYU HARYANA PMJAY",

        "nominal_contribution_collection": "Nominal Contribution Collection",

        "click_to_apply": "Click to Apply",

        "undertaking": "UNDERTAKING",

        "pmjay_chirayu": "1. PMJAY CHIRAYU scheme provides benefits of health coverage of ₹5 Lakhs per family per year for secondary and tertiary treatment in empanelled hospitals.",

        "under_the_scherme": "2. Under the scheme of extension, the benefits of Ayushman Bharat shall be provided to beneficiaries with verified annual family income ranging from ₹1.80 Lakhs to ₹ 3.00 Lakhs per year as per Parivaar Pehchaan Patra ID (PPP ID).",

        "a_nominal_contribution": "3. A nominal contribution-cum-registration fees of ₹1500/- per family per year to be paid by the beneficiary family.",

        // "baneficiary_family_shall": "4. Beneficiary families shall pay the nominal contribution-cum-registration fees from 15/08/2023 and continue until we provide further notice.",

        "after_successful": "4. After successful verification and submission of nominal contribution, cards shall get activated from 01.11.2023 enabling beneficiary for treatment at empanelled hospitals.",

        "six_line": "5. Any beneficiary family that makes a payment between 1st January to 31st March, 2024 will begin receiving benefits from April 1, 2024.",


        "i_hereby_submit": "I hereby submit that I understand above Terms and Conditions and give my consent for enrollment under the scheme upon successful verification and payment.",

        "agree_and_continue": "Agree & Continue",

        "please_accept_the": "Please accept the Terms and Conditions",

        // I hereby submit that I understand above Terms and Conditions and give my consent for enrollment under the scheme upon successful verification and payment.



        // ###############transaction enq

        "no_data_found": "No Data Found",

        "transaction_id_length_exceed": "Transaction ID length exceed",

        "required": "Required",

        "transaction_id": "Transaction ID",

        "ppp_id": "PPP ID",

        "print": "Print",

        "close": "Close",





        //############ppp-verification - VerifyDetails

        "enter_the_otp": "Enter the valid OTP",

        "enter_details": "Enter Details",

        "please_enter_ppp_id": "Please enter PPP ID",

        "ppp_id_length_exceed": "PPP ID length exceed",

        "please_enter_uid": "Please enter UID",

        "invalid_uid": "Invalid UID",

        "enter_valid_8_digit_ppp": "Enter the Valid 8 digit PPP ID",

        "uid_required": "UID is required",

        "your_pppid_aadhar": "Your PPP ID/ Aadhar Number",

        "search_ppp_id": "Your PPP ID",

        "search_aadhar_number": "Your UID",

        "otp_length": "OTP should be 6 digit only",

        "enter_uid": "Enter the UID",

        "verified": "Verified",

        "verify": "Verify",

        "submit": "Submit",

        "we_will_send_you_a": "We will send you a verification code on registered mobile number to verify the PPP ID",

        "continue": "Continue",

        "enter_the_pppid_or_aadhar_num": "Enter PPP ID",

        "i_forgot_my_ppp": " I forgot my PPP ID (Search it via UID)",

        "please_select_the_option": "Please select the option",

        "otp_verification": "OTP Verification",

        "enter_otp": "Enter the OTP",

        "do_not_recevie_the_otp": "Didn't receive the OTP?",

        "resend": "Resend",

        // "verify": "Verify",

        "aadhar_number": "Aadhar Number",







        //###########preview-details

        "ppp_details": "PPP Details",

        "ppp_id_number": "PPP ID Number",

        "id": "ID",

        "name": "Name ",

        "district": "District",

        "sub_district": "Sub-District",

        "linked_member_info": "Linked Member Info",

        "proceed_further_for_payment": "Proceed further for payment",

        "note_for_pppid_related": "Note: For PPP ID related grievance (https://grievance.edisha.gov.in/)",

        "check_eligibility": "Check Eligibility",

        "family_head_name": "Family Head Name",

        "address": "Address",

        "family_income": "Family Income",





        //################ payment-handler

        "payment_details": "Payment Details",

        "payment_for": "Payment For",

        // "ppp_id" : "PPP ID",

        "amount_to_be_paid": "Amount to be paid",

        "currency": "₹",

        "payment_partner": "Payment Partner Canara Bank",
        "payment_partner_csc": "Payment Partner CSC (Digital Seva Connect) Payment",

        "payment_can_be_done_using": "Payments can be done using UPI / Online Wallets / Debit Cards / Net banking / Credit Cards (As per bank availability)",

        "make_payment": "Make Payment",

        "please_select_a_bank": "Please select a bank",





        //################### Transaction Charges

        "transaction_charges": "Transaction Charges",

        "s_no": "Sr. No.",

        "payment_mode": "Payment Mode",

        "rate_of_transaction": "Rate of Transactions (Excluding GST)",

        "debit_card_rupay": "Debit Card (Rupay)*",

        "as_per_rbi_nil": "As Per RBI (Nil)",

        "debit_card_visa_master": "Debit Card (VISA/ MASTER)",

        "upto_2000": "Upto Rs. 2000/-",

        "above_200": "Above Rs. 2000/-",

        "as_per_rbi_0_40": "As Per RBI (0.40%)",

        "as_per_rbi_0_90": "As Per RBI (0.90%)",

        "credit_card": "Credit Card",

        "unified_payment_interface": "Unified Payments Interface (UPI)*",

        "wallets": "Wallets",

        "internet_banking": "Internet Banking",

        "rs_16": "Rs 16",





        //################payment-response



        "payment": "Payment",

        "successful": "successful",
        "success ": "success ",
        "failed": "failed",
        "initiated ": "initiated ",
        "refunded ": "refunded ",
        "aborted ": "aborted ",


        "reference_number": "Reference Number",

        "your_payment_is": "Your payment is",

        "please_find_the_detail": "Please find the details below",

        "mobile_no_email": "Mobile No.",

        "data_time": "Date & Time",

        "amount": "Amount",

        "payment_bank_name": "Payment Bank Name",

        "payment_status": "Payment Status",

        "exit": "Exit",





        //############## transaction Enquery

        "check_payment_status": "Check Payment Status",

        "view": "View",

        "payment_receipt": "Payment Receipt",

        "payment_related_support": "Payment Related Support Helpline",
        "payment_partner_bank": "Payment Partner",
        "bank_name": "Canara Bank",
        "email_id": "Email ID",
        "alert_msg": "पंजीकरण की सुविधा अगली सूचना तक अस्थायी रूप से निलंबित है / Facility of Registration is temporarily suspended till further notice."

    },





    "hi": {

        // #############घर

        "select_language": "भाषा चुनें",

        "chirayu_haryana_pmjay": "चिरायु हरियाणा प्रधानमंत्री जन आरोग्य योजना",

        "nominal_contribution_collection": "नाममात्र योगदान संग्रह",

        "click_to_apply": "आवेदन के लिए क्लिक करें",

        "undertaking": "प्रतिज्ञान",

        "pmjay_chirayu": "1. पीएमजेएवाई चिरायु योजना सूचीबद्ध अस्पतालों में माध्यमिक और तृतीयक उपचार के लिए प्रति परिवार प्रति वर्ष 5 लाख रुपये के स्वास्थ्य आपूर्ति का लाभ प्रदान करती है।",

        "under_the_scherme": "2. विस्तृत योजना के तहत, आयुष्मान भारत का लाभ परिवार पहचान पत्र आईडी (पीपीपी आईडी) के अनुसार प्रति वर्ष ₹ 1.80 लाख से ₹ 3.00 लाख तक की सत्यापित वार्षिक पारिवारिक आय वाले लाभार्थियों को प्रदान किया जाएगा।",

        "a_nominal_contribution": "3. लाभार्थी परिवार द्वारा प्रति वर्ष प्रति परिवार 1500 रुपये का मामूली योगदान-सह-पंजीकरण शुल्क का भुगतान किया जाना है।",

        // "baneficiary_family_shall": "4. लाभार्थी परिवार 15/08/2023 से नाममात्र योगदान-सह-पंजीकरण शुल्क का भुगतान करेंगे और जब तक हम अगली सूचना नहीं देंगे तब तक जारी रखेंगे।",

        "after_successful": "4. सफल सत्यापन और नामी योगदान के प्रस्तुतीकरण के बाद, कार्ड 01.11.2023 से सक्रिय हो जाएंगे जिससे लाभार्थी को आपूर्ति हॉस्पिटलों में उपचार के लिए सक्षम होने में सहायता मिलेगी।",

        "six_line": "5. जो भी लाभार्थी परिवार 1 जनवरी से 31 मार्च, 2024 के बीच भुगतान करेगा, उसे 1 अप्रैल, 2024 से लाभ मिलना शुरू हो जाएगा।",

        "i_hereby_submit": "मैं यहाँ प्रस्तावित नियमों और शर्तें को समझता हूँ और सफल सत्यापन और भुगतान के बाद योजना के तहत पंजीकरण के लिए अपनी सहमति देता हूँ।",

        "agree_and_continue": "सहमत और जारी रखें",

        "please_accept_the": "कृपया नियम और शर्तें स्वीकार करें",



        // ###############लेन-देन पूछताछ

        "no_data_found": "कोई डेटा नहीं मिला",

        "transaction_id_length_exceed": "लेन-देन आईडी की लंबाई अधिक",

        "required": "आवश्यक",

        "transaction_id": "लेन-देन आईडी",

        "ppp_id": "पीपीपी आईडी",

        "print": "प्रिंट करें",

        "close": "बंद करें",



        //############ppp-सत्यापन - विवरण सत्यापित करें

        "enter_the_otp": "वैध ओटीपी दर्ज करें",

        "enter_details": "विवरण दर्ज करें",

        "please_enter_ppp_id": "कृपया पीपीपी आईडी दर्ज करें",

        "ppp_id_length_exceed": "पीपीपी आईडी की लंबाई अधिक",

        "please_enter_uid": "कृपया यूआईडी दर्ज करें",

        "invalid_uid": "अमान्य यूआईडी",

        "enter_valid_8_digit_ppp": "मान्य 8 अंकों वाली पीपीपी आईडी दर्ज करें",

        "uid_required": "यूआईडी की आवश्यकता है",

        "your_pppid_aadhar": "आपकी पीपीपी आईडी / आधार नंबर",

        "search_ppp_id": "आपकी पीपीपी आईडी",

        "search_aadhar_number": "आपकी यूआईडी",

        "otp_length": "ओटीपी केवल 6 अंकों का होना चाहिए",

        "enter_uid": "यूआईडी दर्ज करें",

        "verified": "सत्यापित हुआ",

        "verify": "सत्यापित करें",

        "submit": "प्रस्तुत करें",

        "we_will_send_you_a": "हम आपको पंजीकृत मोबाइल नंबर पर सत्यापन कोड भेजेंगे, पीपीपी आईडी को सत्यापित करने के लिए",

        "continue": "जारी रखें",

        "enter_the_pppid_or_aadhar_num": "पीपीपी आईडी दर्ज करें",

        "i_forgot_my_ppp": "मैंने अपना पीपीपी आईडी भूल गया (यूआईडी के माध्यम से खोजें)",

        "please_select_the_option": "कृपया विकल्प चुनें",

        "otp_verification": "ओटीपी सत्यापन",

        "enter_otp": "ओटीपी दर्ज करें",

        "do_not_recevie_the_otp": "क्या आपने ओटीपी प्राप्त नहीं की?",

        "resend": "पुन: भेजें",

        // "verify": "सत्यापित करें",

        "aadhar_number": "आधार नंबर",



        //###########पूर्वावलोकन-विवरण

        "ppp_details": "पीपीपी विवरण",

        "ppp_id_number": "पीपीपी आईडी नंबर",

        "id": "आईडी",

        "name": "नाम",

        "district": "जिला",

        "sub_district": "उप-जिला",

        "linked_member_info": "जुड़े हुए सदस्य की जानकारी",

        "proceed_further_for_payment": "भुगतान के लिए आगे बढ़ें",

        "note_for_pppid_related": "नोट: पीपीपी आईडी संबंधित शिकायत (https://grievance.edisha.gov.in/)",

        "check_eligibility": "पात्रता देखें",

        "family_head_name": "परिवार के प्रमुख का नाम",

        "address": "पता",

        "family_income": "परिवार की आय",



        //################ पेमेंट-हैंडलर

        "payment_details": "भुगतान विवरण",

        "payment_for": "के लिए भुगतान",

        // "ppp_id" : "पीपीपी आईडी",

        "amount_to_be_paid": "भुगतान की जाने वाली राशि",

        "currency": "₹",

        "payment_partner": "भुगतान साथी केनरा बैंक",
        "payment_partner_csc": "भुगतान भागीदार सीएससी (डिजिटल सेवा कनेक्ट) भुगतान",

        "payment_can_be_done_using": "भुगतान करने के लिए यूपीआई / ऑनलाइन वॉलेट / डेबिट कार्ड / नेट बैंकिंग / क्रेडिट कार्ड का उपयोग किया जा सकता है (बैंक की उपलब्धता के अनुसार)",

        "make_payment": "भुगतान करें",

        "please_select_a_bank": "कृपया एक बैंक का चयन करें",



        //################### लेन-देन शुल्क

        "transaction_charges": "लेन-देन शुल्क",

        "s_no": "क्र.सं.",

        "payment_mode": "भुगतान मोड",

        "rate_of_transaction": "लेन-देन दर (जीएसटी से बाहर)",

        "debit_card_rupay": "डेबिट कार्ड (रुपे)*",

        "as_per_rbi_nil": "आरबीआई के अनुसार (शून्य)",

        "debit_card_visa_master": "डेबिट कार्ड (वीजा/ मास्टर)",

        "upto_2000": "₹2000/- तक",

        "above_200": "₹2000/- से ऊपर",

        "as_per_rbi_0_40": "आरबीआई के अनुसार (0.40%)",

        "as_per_rbi_0_90": "आरबीआई के अनुसार (0.90%)",

        "credit_card": "क्रेडिट कार्ड",

        "unified_payment_interface": "एकीकृत भुगतान इंटरफ़ेस (यूपीआई)*",

        "wallets": "वॉलेट",

        "internet_banking": "इंटरनेट बैंकिंग",

        "rs_16": "₹16",



        //################पेमेंट-प्रत्युत्तर

        "payment": "भुगतान",

        "successful": "सफल",
        "success ": "सफलता ",
        "failed": "असफल",
        "initiated ": "प्रारंभिक ",
        "refunded ": "वापस ",
        "aborted ": "रद्द ",


        "reference_number": "संदर्भ संख्या",

        "your_payment_is": "आपका भुगतान",

        "please_find_the_detail": "कृपया नीचे विवरण देखें",

        "mobile_no_email": "मोबाइल नंबर",

        "data_time": "तारीख और समय",

        "amount": "राशि",

        "payment_bank_name": "भुगतान बैंक का नाम",

        "payment_status": "भुगतान स्थिति",

        "exit": "बाहर निकलें",



        //############## लेन-देन पूछताछ

        "check_payment_status": "भुगतान स्थिति जांचें",

        "view": "देखें",

        "payment_receipt": "भुगतान रसीद",

        "payment_related_support": "भुगतान संबंधित सपोर्ट हेल्पलाइन",
        "payment_partner_bank": "भुगतान साथी",
        "bank_name": "कैनरा बैंक",
        "email_id": "ईमेल आईडी",
        "alert_msg": "पंजीकरण की सुविधा अगली सूचना तक अस्थायी रूप से निलंबित है / Facility of Registration is temporarily suspended till further notice."
    }

}


export const currentSiteLanguage = () => {
    const searchParam = window.location.search.slice(1)
    const queryString = new URLSearchParams(searchParam?.toString());
    const queryStringData = Object.fromEntries(queryString.entries());
    const hasQueryParam = queryString.has('lang');
    if (!hasQueryParam) {
        return "hi"
    } else {

        return queryStringData?.lang
    }

}




