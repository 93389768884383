import React from 'react'
import Ticker from 'react-ticker'

const MoveStuffAround = ({ message }) => {

    // console.log(message)
    return (
        <Ticker
            mode={"smooth"}
            speed={4}
        >
            {({ index }) => (
                <h6 className="alert_msg_text" key={index}>{message}</h6>
            )}
        </Ticker>
    )
}

export default MoveStuffAround