
import { Routes, Route, useLocation } from "react-router-dom"
import './App.css';
import Home from "./pages/home/Home";
import VerifyDetails from "./pages/verify/VerifyDetails";
import PreviewDetails from "./pages/verify/PreviewDetails";
import PaymentHandler from "./pages/verify/PaymentHandler";
import MyContext from "./hooks/MyContext";
import './theme.scss'
import { currentSiteLanguage, mutliLng } from "./multi-language/config";
import PaymentDetail from "./pages/payment-collection/PaymentDetail";
import PageNotFound from "./pages/page-not-found/PageNotFound";
import track from "react-tracking";
import { commonService } from "./service/commonService";
import { SaveLogs } from "./utilities/SaveLogs";
import { useContext, useEffect, useState } from "react";
// import IdleTimerManager from "./components/idle/IdleTimerContainer";
// import IdleTimerContainer from "./components/idle/IdleTimerContainer";
import { useDispatch } from "react-redux";
import { clearAuthSession } from "./slice/login/loginSlice";
import { API_URL } from "./config/config";
import MoveStuffAround from "./components/marquee/MoveStuffAround";
// import IdleTimerContainer from "./components/idle/IdleTimerCont";
// import IdleTimerCont from "./components/idle/IdleTimerCont";




function App() {
  const dispatch = useDispatch()


  const [isCSCUser, setisCSCUser] = useState(false)

  // site language
  // const context = useContext(MyContext);
  const [siteLanguage, setSiteLanguage] = useState("en");
  const location = useLocation()

  useEffect(() => {
    setSiteLanguage(currentSiteLanguage())
  }, [location])



  useEffect(() => {
    const auth_ssid = sessionStorage.getItem("auth_ssid")
    if (auth_ssid !== null) {
      setisCSCUser(true)
    }

  }, [])



  const handleOnIdle = () => {
    // console.log('User is idle , logout');
    dispatch(clearAuthSession())
    setisCSCUser(false)
    window.location.href = API_URL.csc_service_frontend_url

    // You can perform any action here, like logging out the user or showing a warning message
  };


  const contextValue = mutliLng;
  // const currentSiteLng = currentSiteLanguage()
  // setSiteLanguage()
  // console.log(currentSiteLng)
  const alertMsg = contextValue[siteLanguage]["alert_msg"]
  // console.log(alertMsg)

  return (
    <MyContext.Provider value={contextValue}>
      <MoveStuffAround message={alertMsg} />
      <div className="background"></div>
      <div className="App">
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/ppp-verification/" exact element={<VerifyDetails />} />
          <Route path="/preview-details/" exact element={<PreviewDetails />} />
          <Route path="/payment-handler/" exact element={<PaymentHandler />} />
          <Route path="/payment-response/:spTransactionId" exact element={<PaymentDetail />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </MyContext.Provider>
  );
}
// console.log("commonService",commonService)


// functional component usage
const TrackedApp = track(
  // app-level tracking data
  {},

  // top-level options
  {
    // custom dispatch to console.log in addition to pushing to dataLayer[]
    dispatch: (data) => {
      // window.dataLayer
      (SaveLogs(data));
      (window.dataLayer = window.dataLayer || []).push(data);
    }
  }
)(App);

export default TrackedApp;
