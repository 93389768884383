import React from 'react'
import { API_URL } from '../../../config/config'
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

function PgForm({ paymentData, cscData }) {
    const formRef = useRef(null);
    const [cscUserId, setcscUserId] = useState("")
    // console.log(paymentData)

    useEffect(() => {
        // console.log(paymentData)
        // console.log(cscData)
        const cscID = cscData?.authUserData?.user?.csc_id
        const cscstatus = cscData?.authUserData?.status
        setcscUserId(cscID)
        if (paymentData?.clientTxnId !== "" && paymentData?.clientTxnId !== undefined && cscID!==undefined && cscstatus===true) {
            formRef.current.submit()
        }
    }, [paymentData, cscData])


    return (
        <form action={API_URL.csc_payment_form} method="post" id="seemless_pg_form" ref={formRef}>
            <input type="hidden" name="client_trans_id" id="client_trans_id" value={paymentData?.clientTxnId} />
            <input type="hidden" name="csc_id" id="csc_id" value={cscUserId} />
        </form>

    )
}

export default PgForm