import React, { useContext, useEffect, useState } from 'react'
import Card from '../../components/card/Card'
import { useLocation, useNavigate } from 'react-router-dom'
import classes from "./home.module.css"
import Navbar from '../../components/navbar/Navbar'
import { useDispatch } from 'react-redux'
import { undertakingForm } from '../../slice/home/homeSlice'
import MyContext from '../../hooks/MyContext'
import { currentSiteLanguage } from '../../multi-language/config'
import TransactionEnq from './TransactionEnq'
import { clearSessionData } from '../../slice/verifyDetails/verifyDetailSlice'
import { useTracking } from 'react-tracking'
// import uniqueId from 'uniqid';
// import { v4 as uuidv4 } from 'uuid';
import { SessionId } from '../../utilities/SessionId'
// import { useNavigation } from '../../hooks/useNavigation'
// useNavigation
// import {uniqe}
// import { useNavigation } from "../../hooks/useNavigation"
import { fetchDataBySession } from '../../slice/login/loginSlice'
import { useQueryParams } from '../../hooks/useQueryParams'
import MoveStuffAround from '../../components/marquee/MoveStuffAround'



function Home() {
    // console.log("first")
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { trackEvent } = useTracking()
    // const { navigateWithQuery } = useNavigation();
    const { deleteQueryParam } = useQueryParams();

    const [clickToApply, setClickToApply] = useState(false)
    const [isChecked, setIsChecked] = useState(false);
    const [isError, setIsError] = useState(false);
    const [enablePayState, setEnablePayState] = useState(false);


    // site language
    const context = useContext(MyContext);
    const [siteLanguage, setSiteLanguage] = useState("en");
    const location = useLocation()
    useEffect(() => {
        setSiteLanguage(currentSiteLanguage())

        const searchParam = window.location.search.slice(1)
        const queryString = new URLSearchParams(searchParam?.toString());
        const queryStringData = Object.fromEntries(queryString.entries());
        // console.log(queryStringData?.auth)

        if (queryStringData?.auth !== undefined && queryStringData?.auth !== "") {
            // console.log("set")
            const postBody = {
                session_id: queryStringData?.auth
            }
            dispatch(fetchDataBySession(postBody))

            sessionStorage.setItem("app_code", queryStringData?.app)
            deleteQueryParam("auth")
            deleteQueryParam("app")
        }

    }, [location])


    const handleSubmit = () => {
        // console.log("isChecked",isChecked)
        setIsError(!isChecked)
        if (isChecked) {
            trackEvent({
                message: "Use/Action/Home Screen - Click on agree & continue",
                ppp_id: "",
                session_id: SessionId()
            })
            dispatch(undertakingForm(isChecked))
            const queryStringUrl = window.location.search;
            // useNavigation()
            // navigateWithQuery(`/ppp-verification/`, queryStringUrl)
            navigate(`/ppp-verification/${queryStringUrl}`)
        } else {
            // setIsError(true)
        }
    };


    useEffect(() => {
        dispatch(clearSessionData())
        sessionStorage.removeItem("cta_payment")
        sessionStorage.removeItem("ssid")
        trackEvent({
            message: "User/Action/Log Initiate - Home Screen",
            ppp_id: "",
            session_id: SessionId()
        })
    }, [])


    const cardHeading = (
        <h4 className="text-center m-0 text-primary-color">{context[siteLanguage]["undertaking"]}</h4>
    );


    const cardBody = (
        <div className="bg-light">
            <div className={`font-14 p-2`}>
                <p >{context[siteLanguage]["pmjay_chirayu"]}</p>
                <p >{context[siteLanguage]["under_the_scherme"]} </p>
                <p >{context[siteLanguage]["a_nominal_contribution"]} </p>
                {/* <p >{context[siteLanguage]["baneficiary_family_shall"]} </p> */}
                <p >{context[siteLanguage]["after_successful"]} </p>
                <p >{context[siteLanguage]["six_line"]} </p>
            </div>
            <div className="form-check mt-1">
                <hr className="mb-1" />
                <input className={`form-check-input ${classes.check_box}`} type="checkbox" id="flexCheckChecked" onChange={() => setIsChecked(!isChecked)} />
                <label className="form-check-label font-12" htmlFor="flexCheckChecked">
                    {context[siteLanguage]["i_hereby_submit"]}
                </label>
            </div>
        </div>
    )


    const cardFooter = (
        <div className="text-center mt-1">
            <button onClick={() => handleSubmit()} className={`btn btn-sm btn-primary-color`} /*disabled={!isChecked}*/ >{context[siteLanguage]["agree_and_continue"]} </button>
            {isError && <p className="text-danger">{context[siteLanguage]["please_accept_the"]} </p>}
        </div>
    )

    const backButtonHandler = (val) => {
        setClickToApply(false)
    }

    const configCard = {
        enableIcon: true,
        backHandler: (e) => { backButtonHandler(e) }
    }

    return (
        <div className={`container-fluid`}>
            <Navbar enableLogo={true} forceDisable={!clickToApply} disableTxnEnq={true} />
            {enablePayState && <TransactionEnq closeModalFn={setEnablePayState} />}

            <div className="row">
                {/* notice text */}
                <div className="col-lg-12 text-center mt-5 fw-bold">
                    <h3>पंजीकरण की सुविधा अगली सूचना तक अस्थायी रूप से निलंबित है </h3>
                    <h3>Facility of Registration is temporarily suspended till further notice.</h3>
                </div>
                {clickToApply ?
                    <div className="col-lg-6 justify-content-center px-4">
                        <Card heading={cardHeading} body={cardBody} footer={cardFooter} configCard={configCard} />
                    </div>
                    :
                    <div className="col-lg-6 px-4">
                        {/* <div className="text-center justify-content-center mt-5">
                            <h3 className="mb-1">{context[siteLanguage]["chirayu_haryana_pmjay"]}</h3>
                            <h1 className="text-primary-color">{context[siteLanguage]["nominal_contribution_collection"]}</h1>
                            <button
                                disabled={true}
                                className="btn btn-sm btn-primary-color mt-4 mb-1"
                            // onClick={() => setClickToApply(!clickToApply)}
                            >{context[siteLanguage]["click_to_apply"]}</button>

                            <div className="m-2">
                                <button

                                    // onClick={() => {
                                    //     setEnablePayState(true)
                                    //     trackEvent({
                                    //         message: "User/Action/Click - Check Payment Status",
                                    //         ppp_id: "",
                                    //         session_id: SessionId()
                                    //     })
                                    // }}
                                    disabled={true}
                                    className="btn btn-primary-color btn-sm">{context[siteLanguage]['check_payment_status']}</button>
                            </div>
                        </div> */}
                    </div>
                }

                {!clickToApply && <div className="suppport p-2"> <div><p className="mb-0"> {context[siteLanguage]["payment_partner_bank"]} - {context[siteLanguage]["bank_name"]}</p>  {context[siteLanguage]["payment_related_support"]} <p>{context[siteLanguage]["email_id"]}  : chirayuextension@gmail.com</p> </div></div>}
            </div>
        </div>
    )
}

export default Home