import React, { useContext, useEffect, useState } from 'react'
import SabpaisaPaymentGateway from '../sabpaisa-pg/SabpaisaPaymentGateway';
import Card from '../../components/card/Card';
import Navbar from '../../components/navbar/Navbar';
import { API_URL, sp_client_code } from '../../config/config';
import MyContext from '../../hooks/MyContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { currentSiteLanguage } from '../../multi-language/config';
import { useTracking } from 'react-tracking';
import { SessionId } from '../../utilities/SessionId';
import { axiosSession as axios } from '../../utilities/axiosInstance';
import PgForm from '../sabpaisa-pg/seemless-integration/PgForm';
import { fetchDataBySession } from '../../slice/login/loginSlice';

function PaymentHandler() {

    const [toggleCard, setToggleCard] = useState(false)
    const [isOpenPg, setIsOpenPg] = useState(false)
    const [paymentLoader, setPaymentLoader] = useState(false)
    const [enableCSCPayment, setEnableCSCPayment] = useState(false)
    const [paymentBtnDisable, setPaymentBtnDisable] = useState(false)
    const [responseMsg, setResponseMsg] = useState("")
    const [paymentDatastate, setPaymentDataState] = useState({})

    const navigate = useNavigate();
    const isLoggedIn = JSON.parse(sessionStorage.getItem("isLoggedIn"))
    if (!isLoggedIn) {
        navigate("/")
    }

    const dispatch = useDispatch();
    const { trackEvent } = useTracking()
    // const location = useLocation();
    const { verify, loginReducer } = useSelector(state => state)
    // console.log("loginReducer",verify)


    const searchParam = window.location.search.slice(1)
    const queryString = new URLSearchParams(searchParam?.toString());
    const queryStringData = Object.fromEntries(queryString.entries());

    // console.log("context",context)


    // site language detecter
    const context = useContext(MyContext);
    const [siteLanguage, setSiteLanguage] = useState("en");
    const location = useLocation()
    useEffect(() => {
        setSiteLanguage(currentSiteLanguage())
    }, [location])


    const configAxios = {
        headers: {
            "Accept-Language": siteLanguage.toUpperCase()
        }
    }

    useEffect(() => {
        if (queryStringData?.clientTxnId) {
            trackEvent({
                message: `User/Action/Payment-handler/Payment Response Capture from the URL - ${JSON.stringify(queryStringData)}`,
                ppp_id: `clientTxnId-${queryStringData?.clientTxnId}`,
                session_id: SessionId()
            })
            sessionStorage.setItem("sptxnval", queryStringData?.clientTxnId)
            sessionStorage.setItem("cta_payment", 2);
            // console.log("nav-pr-redirect")
            navigate(`/payment-response/${queryStringData?.clientTxnId}`)
        } else {
            setTimeout(() => {
                setToggleCard(true)
            }, 2000);

        }


    }, [])

    useEffect(() => {
        const auth_ssid = sessionStorage.getItem("auth_ssid")
        if (auth_ssid !== null && auth_ssid !== "") {
            const postBody = {
                session_id: auth_ssid
            }
            dispatch(fetchDataBySession(postBody));
        }
    }, [])




    // console.log("verify?.paymentPostData", verify?.paymentPostData)
    const handleSubmit = () => {

        setPaymentLoader(true)

        const paymentData = verify?.paymentPostData
        if (paymentData?.pppid_fid === undefined) {
            // navigate("/")
            // console.log("nav-1")
        }

        const app_code = sessionStorage.getItem("app_code")
        const postData = {
            "client_trans_id": paymentData?.clientTxnId,
            "payer_name": paymentData?.payerName,
            "payer_mobile": paymentData?.payerMobile,
            "payer_email": paymentData?.payerEmail,
            "gateway_name": "SabPaisa",
            "pg_partner_bank": "Canara Bank",
            // "scheme_id": paymentData?.scheme_id,
            "trans_amount": paymentData?.amount,
            // "crid": paymentData?.crid,
            "pppid_fid": paymentData?.pppid_fid,
            "uid": paymentData.uid,
            "district_name": paymentData.district_name,
            "state": paymentData.state,
            "tehsil_code": paymentData.tehsil_code,
            "family_district_code": paymentData.family_district_code,
            "trans_init_date": paymentData.trans_init_date,
            "client_code": sp_client_code, // change in production
            "csc_id": loginReducer?.authUserData?.user?.csc_id,
            "vle_full_name": loginReducer?.authUserData?.user?.fullname,
            "app_code": app_code
            // loginReducerauthUserDatausercsc_id
        }
    //   alert(JSON.stringify(postData))
        trackEvent({
            message: `User/Action/Payment-handler/Click - Make Payment - ${JSON.stringify(postData)}`,
            ppp_id: paymentData?.pppid_fid,
            session_id: SessionId()
        })
        axios.post(API_URL.save_transaction, postData, configAxios).then(resp => {
            setPaymentLoader(false)
            // setBtnDisable(false)
            setIsOpenPg(!isOpenPg)
            setPaymentDataState(paymentData)
            setEnableCSCPayment(true)
            sessionStorage.setItem("cta_payment", 1);
            // alert(paymentData)
            trackEvent({
                message: `User/Action/Payment-handler/make pre-payment response -  - ${JSON.stringify(resp)}`,
                ppp_id: paymentData?.pppid_fid,
                session_id: SessionId()
            })
        }).catch(err => {
            //  toastConfig.errorToast("#100 : Something went wrong")
            setPaymentLoader(false)
            setEnableCSCPayment(false)
            setPaymentBtnDisable(true)
            // setBtnDisable(false)
            setResponseMsg(err?.response?.data?.message)
            trackEvent({
                message: `User/Action/Payment-handler/make pre-payment response -  - ${JSON.stringify(err?.response?.data)}`,
                ppp_id: paymentData?.pppid_fid,
                session_id: SessionId()
            })
            // console.log(err?.response?.data?.message)
        })

    };


    const cardHeading = (
        <h5 className="text-center text-primary-color link-underline-dark">{context[siteLanguage]['payment_details']}</h5>
    )


    const auth_ssid = sessionStorage.getItem("auth_ssid")
    // console.log(auth_ssid)

    const cardBody = (
        <React.Fragment>
            <div>
                <p className="m-0 ">{context[siteLanguage]['ppp_id']} : {verify?.paymentPostData?.pppid_fid}</p>
                <p className="m-0 ">{context[siteLanguage]['amount_to_be_paid']} : {context[siteLanguage]['currency']} {verify?.paymentPostData?.amount}</p>
            </div>
            <div className="mt-4">
                <hr />

                {(auth_ssid !== null && auth_ssid !== "") ? <h5 className="text-center">{context[siteLanguage]['payment_partner_csc']}</h5> : <h5 className="text-center">{context[siteLanguage]['payment_partner']}</h5>}
                <div className="form">
                    <hr />
                    {(auth_ssid !== null && auth_ssid !== "") ? <></> : <p className="font-12 mt-3">{context[siteLanguage]['payment_can_be_done_using']}</p>}
                    <div className="text-center mt-2">
                        <p className="text-danger">
                            {responseMsg !== "" && responseMsg}
                        </p>
                        { !paymentBtnDisable &&
                        <button className="btn btn-sm btn-primary-color" onClick={() => { handleSubmit() }} disabled={paymentLoader}>
                        {paymentLoader ?
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            : context[siteLanguage]['make_payment']}</button> }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

    const cardFooter = (<></>)

    const backButtonHandler = (val) => {
            navigate("/")
    }


    const configCard = {
        width: "",
        enableIcon: true,
        backHandler: (e) => { backButtonHandler(e) },
    }

    const cta_payment = JSON.parse(sessionStorage.getItem("cta_payment"))
    // console.log("cta_payment",cta_payment)
    // cta_payment = 0,1,2

    if (cta_payment === null) {

        // console.log("nav-null")
        navigate("/")
    } else {
        if (cta_payment === 2) {
            // check why this component call after route
            // setTimeout(() => {
            //     navigate("/")
            // }, 2000);
            // console.log("nav-2")
        }
    }

    // console.log("loginReducer", loginReducer)
    // console.log("isOpenPg", isOpenPg)
    return (
        <React.Fragment>
            {queryStringData?.clientTxnId ?
                <div className="container-fluid">
                    <div className="text-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
                :

                <div className="container-fluid background-image">
                    <Navbar enableLogo={true} />
                    <div className="row">
                        <div className="col-lg-6 justify-content-center align-items-center px-4">
                            <React.Fragment>
                                {!toggleCard ?
                                    <div className="text-center">
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                    :
                                    toggleCard && <Card heading={cardHeading} body={cardBody} footer={cardFooter} configCard={configCard} />
                                }
                                {loginReducer?.isAuthValid !== true && <SabpaisaPaymentGateway openPg={isOpenPg} paymentData={paymentDatastate} />}
                                {(loginReducer?.isAuthValid === true && enableCSCPayment === true) && <PgForm paymentData={paymentDatastate} cscData={loginReducer} />}
                            </React.Fragment>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>

    )
}

export default PaymentHandler